import React from "react";
import Client from "shopify-buy";
import LoadingSpinner from "../LoadingSpinner";
import { UserContext } from "../../lib/context";
import { Link } from "react-router-dom";

class NorandMonoMK2ProductCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  static contextType = UserContext;

  orderNow = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    console.log(this.context.psu);
    this.redirectToCheckout = true;
    const client = Client.buildClient({
      storefrontAccessToken: "c715561b4f24fbaeb3555426faa361c9",
      domain: "embodme-norand.myshopify.com",
    });
    client.checkout.create().then((checkout) => {
      const checkoutId = checkout.id;
      const lineItemsToAdd = [
        {
          variantId: (this.context.psu == 0) ? "gid://shopify/ProductVariant/50760275951947" : // EU
          (this.context.psu == 1) ? "gid://shopify/ProductVariant/50760275984715" : // US
          "gid://shopify/ProductVariant/50760276017483", // UK
          quantity: 1,
        },
      ];

      const shippingAddress = {
        address1: "",
        address2: "",
        city: "",
        company: null,
        country: this.context.country,
        firstName: "",
        lastName: "",
        phone: "",
        zip: "",
      };

      client.checkout
        .updateShippingAddress(checkoutId, shippingAddress)
        .then((checkout) => {
        });

        client.checkout
        .addLineItems(checkoutId, lineItemsToAdd)
        .then((checkout) => {
          window.location.href = checkout.webUrl;
        });
    });
  };
  render() {
    return (
      <>
        {this.state.loading && <LoadingSpinner />}
        <div className="creativityMonoContainer">
          <div className="backdrop-blur">
            <div className="anim-bg">
              <div className="anim-form-1"></div>
              <div className="anim-form-2"></div>
              <div className="anim-form-3"></div>
              <div className="anim-form-4"></div>
              <div className="anim-form-5"></div>
              <div className="anim-form-6"></div>
              <div className="anim-form-7"></div>
            </div>
          </div>
          <div className="mainCreativity">
            <p className="p1Creativity">{this.props.mainText}</p>
            <p className="headerCreativityNorand">
              {this.props.NORANDTxt}
              <span className="headerCreativityMono">{this.props.MONO} </span>
              <span className="headerCreativityMonoMK2">{this.props.MK2} </span>
            </p>
            <img src={this.props.picCut} alt="" className="cutMonoImgMK2" />

            <button onClick={this.orderNow} className="btnOrderNow">
                {this.props.btnText}
                <UserContext.Consumer>
                  {(priceInEur) => (priceInEur ? "€" : "$")}
                </UserContext.Consumer>
                <img
                  src={this.props.buttArrowPic}
                  alt=""
                  className="arrowButtonMonoProduct"
                />
              </button>
          </div>
        </div>
      </>
    );
  }
}

export default NorandMonoMK2ProductCard;
