import React from "react";
import { UserContext } from "../../lib/context";
import { countryData } from "../../lib/countryData";
import { Alert } from "react-bootstrap";

import "./AcceptCookies.css";

class AcceptCookies extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    if (localStorage.getItem("cookieAccept")) {
      this.state = { showCookieAccept: false, fadeToDark: false };
    } else {
      this.state = { showCookieAccept: true, fadeToDark: false };
    }
  }

  options = [
    { value: "English", label: "English" },
    { value: "French", label: "French" },
  ];

  acceptCookies = (event) => {
    event.preventDefault();
    this.context.AcceptCookies = true;
    localStorage.setItem("cookieAccept", true);
    this.setState({ showCookieAccept: false });
  };

  componentDidMount() {
    this.setState({ fadeToDark: true });
    fetch(
      "https://ipapi.co/json/?key=QkK5TMnAlHPdSjmGylPsPHIZpPWKkKQn5AzD12CJYn44m8r5PP"
    )
      .then((x) => x.json())
      .then(({ ip, country_name }) => {
        this.context.country = country_name;
        countryData.forEach((el) => {
          if (el.value === this.context.country) {
            this.context.priceInEur = el.eur;
            this.context.psu = el.psu;
            console.log(this.context.country);
            console.log(this.context.priceInEur);
            return;
          }
        });
      })
      .catch(({ error }) => {
        this.country = "United States";
        this.context.priceInEur = false;
      });
  }

  render() {
    return (
      <>
        <div
          hidden={!this.state.showCookieAccept}
          style={
            this.state.fadeToDark
              ? { backgroundColor: "rgba(0, 0, 0, 0.8)" }
              : {}
          }
          id="cookie-mask"
        >
          <Alert
            style={{ display: "flex", alignItems: "center" }}
            className="my-alert-chill"
            show={this.state.showCookieAccept}
          >
            <p>
              This site makes use of cookies to save preferences, logins and
              shopping carts. By continuing to use this site you approve of such
              use of cookies.
            </p>
            <button onClick={this.acceptCookies} className="accept-cookie-btn">
              Accept All
            </button>
          </Alert>
        </div>
      </>
    );
  }
}

export default AcceptCookies;
