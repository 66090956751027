import React from "react";
import btnArrow from "./../../images/ArrowRightWhite.svg";
import picCut from "./../../images/mono_front_color_bg.png";
import "./NorandMonoProduct.css";

import NorandMonoProductCard from "./NorandMonoProductCard";
const NorandMonoProduct = () => {
  const mainText = "Hundreds of features, limitless creativity";
  const NORANDTxt = "NORAND";
  const MONO = "MONO";
  const btnText = "Order now for 699";
  return (
    <>
      <NorandMonoProductCard
        picCut={picCut}
        buttArrowPic={btnArrow}
        mainText={mainText}
        NORANDTxt={NORANDTxt}
        MONO={MONO}
        btnText={btnText}
      />
    </>
  );
};

export default NorandMonoProduct;
