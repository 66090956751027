export const countryData = [
  { value: "Afghanistan", text: "Afghanistan", eur: false, zone: 7, psu: 0 },
  { value: "Albania", text: "Albania", eur: false, zone: 4, psu: 0 },
  { value: "Algeria", text: "Algeria", eur: false, zone: 6, psu: 0 },
  { value: "Andorra", text: "Andorra", eur: true, zone: 4, psu: 0 },
  { value: "Angola", text: "Angola", eur: false, zone: 7, psu: 0 },
  { value: "Anguilla", text: "Anguilla", eur: false, zone: 7, psu: 0 },
  {
    value: "Antigua and Barbuda",
    text: "Antigua and Barbuda",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Argentina", text: "Argentina", eur: false, zone: 7, psu: 0 },
  { value: "Armenia", text: "Armenia", eur: false, zone: 7, psu: 0 },
  { value: "Aruba", text: "Aruba", eur: false, zone: 7, psu: 0 },
  { value: "Australia", text: "Australia", eur: false, zone: 7, psu: 0 },
  { value: "Austria", text: "Austria", eur: true, zone: 2, psu: 0 },
  { value: "Azerbaijan", text: "Azerbaijan", eur: false, zone: 7, psu: 0 },
  { value: "Bahamas", text: "Bahamas", eur: false, zone: 7, psu: 0 },
  { value: "Bahrain", text: "Bahrain", eur: false, zone: 7, psu: 0 },
  { value: "Bangladesh", text: "Bangladesh", eur: false, zone: 6, psu: 0 },
  { value: "Barbados", text: "Barbados", eur: false, zone: 7, psu: 0 },
  { value: "Belarus", text: "Belarus", eur: false, zone: 7, psu: 0 },
  { value: "Belgium", text: "Belgium", eur: true, zone: 1, psu: 0 },
  { value: "Belize", text: "Belize", eur: false, zone: 7, psu: 0 },
  { value: "Benin", text: "Benin", eur: false, zone: 7, psu: 0 },
  { value: "Bermuda", text: "Bermuda", eur: false, zone: 7, psu: 0 },
  { value: "Bolivia", text: "Bolivia", eur: false, zone: 7, psu: 0 },
  {
    value: "Bosnia and Herzegovina",
    text: "Bosnia and Herzegovina",
    eur: false,
    zone: 4,
    psu: 0 },
  { value: "Botswana", text: "Botswana", eur: false, zone: 7, psu: 0 },
  { value: "Brazil", text: "Brazil", eur: false, zone: 7, psu: 0 },
  {
    value: "British Indian Ocean Territory",
    text: "British Indian Ocean Territory",
    eur: false,
    zone: 7,
    psu: 0 },
  {
    value: "Brunei Darussalam",
    text: "Brunei Darussalam",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Bulgaria", text: "Bulgaria", eur: true, zone: 3, psu: 0 },
  { value: "Burkina Faso", text: "Burkina Faso", eur: false, zone: 7, psu: 0 },
  { value: "Burundi", text: "Burundi", eur: false, zone: 7, psu: 0 },
  { value: "Cambodia", text: "Cambodia", eur: false, zone: 6, psu: 0 },
  { value: "Cameroon", text: "Cameroon", eur: false, zone: 7, psu: 0 },
  { value: "Canada", text: "Canada", eur: false, zone: 5, psu: 1 },
  { value: "Cape Verde", text: "Cape Verde", eur: false, zone: 7, psu: 0 },
  {
    value: "Cayman Islands",
    text: "Cayman Islands",
    eur: false,
    zone: 7,
    psu: 0 },
  {
    value: "Central African Republic",
    text: "Central African Republic",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Chad", text: "Chad", eur: false, zone: 7, psu: 0 },
  { value: "Chile", text: "Chile", eur: false, zone: 7, psu: 0 },
  { value: "China", text: "China", eur: false, zone: 6, psu: 0 },
  { value: "Colombia", text: "Colombia", eur: false, zone: 7, psu: 0 },
  { value: "Comoros", text: "Comoros", eur: false, zone: 7, psu: 0 },
  { value: "Congo", text: "Congo", eur: false, zone: 7, psu: 0 },
  { value: "Cook Islands", text: "Cook Islands", eur: false, zone: 7, psu: 0 },
  { value: "Costa Rica", text: "Costa Rica", eur: false, zone: 7, psu: 0 },
  { value: "Cote D'Ivoire", text: "Cote D'Ivoire", eur: false, zone: 7, psu: 0 },
  { value: "Croatia", text: "Croatia", eur: true, zone: 3, psu: 0 },
  { value: "Cuba", text: "Cuba", eur: false, zone: 7, psu: 0 },
  { value: "Cyprus", text: "Cyprus", eur: true, zone: 3, psu: 0 },
  { value: "Czech Republic", text: "Czech Republic", eur: true, zone: 2, psu: 0 },
  { value: "Denmark", text: "Denmark", eur: true, zone: 2, psu: 0 },
  { value: "Djibouti", text: "Djibouti", eur: false, zone: 7, psu: 0 },
  { value: "Dominica", text: "Dominica", eur: false, zone: 7, psu: 0 },
  {
    value: "Dominican Republic",
    text: "Dominican Republic",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Ecuador", text: "Ecuador", eur: false, zone: 7, psu: 0 },
  { value: "Egypt", text: "Egypt", eur: false, zone: 6, psu: 0 },
  { value: "El Salvador", text: "El Salvador", eur: false, zone: 7, psu: 0 },
  {
    value: "Equatorial Guinea",
    text: "Equatorial Guinea",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Eritrea", text: "Eritrea", eur: false, zone: 7, psu: 0 },
  { value: "Estonia", text: "Estonia", eur: false, zone: 3, psu: 0 },
  { value: "Ethiopia", text: "Ethiopia", eur: false, zone: 7, psu: 0 },
  {
    value: "Falkland Islands (Malvinas)",
    text: "Falkland Islands (Malvinas)",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Faroe Islands", text: "Faroe Islands", eur: false, zone: 7, psu: 0 },
  { value: "Fiji", text: "Fiji", eur: false, zone: 7, psu: 0 },
  { value: "Finland", text: "Finland", eur: true, zone: 3, psu: 0 },
  { value: "France", text: "France", eur: true, zone: 1, psu: 0 },
  { value: "French Guiana", text: "French Guiana", eur: true, zone: 6, psu: 0 },
  {
    value: "French Polynesia",
    text: "French Polynesia",
    eur: true,
    zone: 7,
    psu: 0 },
  { value: "Gabon", text: "Gabon", eur: false, zone: 7, psu: 0 },
  { value: "Gambia", text: "Gambia", eur: false, zone: 7, psu: 0 },
  { value: "Georgia", text: "Georgia", eur: false, zone: 7, psu: 0 },
  { value: "Germany", text: "Germany", eur: true, zone: 1, psu: 0 },
  { value: "Ghana", text: "Ghana", eur: false, zone: 7, psu: 0 },
  { value: "Gibraltar", text: "Gibraltar", eur: false, zone: 4, psu: 0 },
  { value: "Greece", text: "Greece", eur: true, zone: 3, psu: 0 },
  { value: "Greenland", text: "Greenland", eur: false, zone: 7, psu: 0 },
  { value: "Grenada", text: "Grenada", eur: false, zone: 7, psu: 0 },
  { value: "Guadeloupe", text: "Guadeloupe", eur: true, zone: 6, psu: 0 },
  { value: "Guam", text: "Guam", eur: false, zone: 7, psu: 0 },
  { value: "Guatemala", text: "Guatemala", eur: false, zone: 7, psu: 0 },
  { value: "Guernsey", text: "Guernsey", eur: false, zone: 4, psu: 0 },
  { value: "Guinea", text: "Guinea", eur: false, zone: 7, psu: 0 },
  { value: "Guinea-Bissau", text: "Guinea-Bissau", eur: false, zone: 7, psu: 0 },
  { value: "Guyana", text: "Guyana", eur: false, zone: 7, psu: 0 },
  { value: "Haiti", text: "Haiti", eur: false, zone: 7, psu: 0 },
  { value: "Honduras", text: "Honduras", eur: false, zone: 7, psu: 0 },
  { value: "Hong Kong", text: "Hong Kong", eur: false, zone: 6, psu: 0 },
  { value: "Hungary", text: "Hungary", eur: true, zone: 3, psu: 0 },
  { value: "Iceland", text: "Iceland", eur: false, zone: 7, psu: 0 },
  { value: "India", text: "India", eur: false, zone: 6, psu: 0 },
  { value: "Indonesia", text: "Indonesia", eur: false, zone: 6, psu: 0 },
  {
    value: "Iran, Islamic Republic Of",
    text: "Iran, Islamic Republic Of",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Iraq", text: "Iraq", eur: false, zone: 7, psu: 0 },
  { value: "Ireland", text: "Ireland", eur: true, zone: 2, psu: 0 },
  { value: "Israel", text: "Israel", eur: false, zone: 4, psu: 0 },
  { value: "Italy", text: "Italy", eur: true, zone: 1, psu: 0 },
  { value: "Jamaica", text: "Jamaica", eur: false, zone: 7, psu: 0 },
  { value: "Japan", text: "Japan", eur: false, zone: 6, psu: 0 },
  { value: "Jersey", text: "Jersey", eur: false, zone: 4, psu: 0 },
  { value: "Jordan", text: "Jordan", eur: false, zone: 7, psu: 0 },
  { value: "Kazakhstan", text: "Kazakhstan", eur: false, zone: 7, psu: 0 },
  { value: "Kenya", text: "Kenya", eur: false, zone: 7, psu: 0 },
  { value: "Kiribati", text: "Kiribati", eur: false, zone: 7, psu: 0 },
  { value: "Korea, North", text: "Korea, North", eur: false, zone: 7, psu: 0 },
  { value: "Korea, South", text: "Korea, South", eur: false, zone: 6, psu: 0 },
  { value: "Kuwait", text: "Kuwait", eur: false, zone: 7, psu: 0 },
  { value: "Kyrgyzstan", text: "Kyrgyzstan", eur: false, zone: 7, psu: 0 },
  {
    value: "Lao People's Democratic Republic",
    text: "Lao People's Democratic Republic",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Latvia", text: "Latvia", eur: true, zone: 3, psu: 0 },
  { value: "Lebanon", text: "Lebanon", eur: false, zone: 7, psu: 0 },
  { value: "Lesotho", text: "Lesotho", eur: false, zone: 7, psu: 0 },
  { value: "Liberia", text: "Liberia", eur: false, zone: 7, psu: 0 },
  {
    value: "Libya",
    text: "Libya",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Liechtenstein", text: "Liechtenstein", eur: false, zone: 4, psu: 0 },
  { value: "Lithuania", text: "Lithuania", eur: true, zone: 3, psu: 0 },
  { value: "Luxembourg", text: "Luxembourg", eur: true, zone: 1, psu: 0 },
  { value: "Macao", text: "Macao", eur: false, zone: 7, psu: 0 },
  { value: "Macedonia", text: "Macedonia", eur: false, zone: 4, psu: 0 },
  { value: "Madagascar", text: "Madagascar", eur: false, zone: 7, psu: 0 },
  { value: "Malawi", text: "Malawi", eur: false, zone: 7, psu: 0 },
  { value: "Malaysia", text: "Malaysia", eur: false, zone: 6, psu: 0 },
  { value: "Maldives", text: "Maldives", eur: false, zone: 7, psu: 0 },
  { value: "Mali", text: "Mali", eur: false, zone: 7, psu: 0 },
  { value: "Malta", text: "Malta", eur: true, zone: 3, psu: 0 },
  {
    value: "Marshall Islands",
    text: "Marshall Islands",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Martinique", text: "Martinique", eur: true, zone: 6, psu: 0 },
  { value: "Mauritania", text: "Mauritania", eur: false, zone: 7, psu: 0 },
  { value: "Mauritius", text: "Mauritius", eur: false, zone: 7, psu: 0 },
  { value: "Mayotte", text: "Mayotte", eur: true, zone: 6, psu: 0 },
  { value: "Mexico", text: "Mexico", eur: false, zone: 5, psu: 0 },
  { value: "Micronesia", text: "Micronesia", eur: false, zone: 7, psu: 0 },
  { value: "Moldova", text: "Moldova", eur: false, zone: 4, psu: 0 },
  { value: "Monaco", text: "Monaco", eur: true, zone: 1, psu: 0 },
  { value: "Mongolia", text: "Mongolia", eur: false, zone: 7, psu: 0 },
  { value: "Montserrat", text: "Montserrat", eur: false, zone: 7, psu: 0 },
  { value: "Morocco", text: "Morocco", eur: false, zone: 6, psu: 0 },
  { value: "Mozambique", text: "Mozambique", eur: false, zone: 7, psu: 0 },
  { value: "Myanmar", text: "Myanmar", eur: false, zone: 7, psu: 0 },
  { value: "Namibia", text: "Namibia", eur: false, zone: 7, psu: 0 },
  { value: "Nauru", text: "Nauru", eur: false, zone: 7, psu: 0 },
  { value: "Nepal", text: "Nepal", eur: false, zone: 7, psu: 0 },
  { value: "Netherlands", text: "Netherlands", eur: true, zone: 1, psu: 0 },
  { value: "New Caledonia", text: "New Caledonia", eur: true, zone: 7, psu: 0 },
  { value: "New Zealand", text: "New Zealand", eur: false, zone: 7, psu: 0 },
  { value: "Nicaragua", text: "Nicaragua", eur: false, zone: 7, psu: 0 },
  { value: "Niger", text: "Niger", eur: false, zone: 7, psu: 0 },
  { value: "Nigeria", text: "Nigeria", eur: false, zone: 7, psu: 0 },
  { value: "Niue", text: "Niue", eur: false, zone: 7, psu: 0 },
  { value: "Norway", text: "Norway", eur: false, zone: 4, psu: 0 },
  { value: "Oman", text: "Oman", eur: false, zone: 7, psu: 0 },
  { value: "Pakistan", text: "Pakistan", eur: false, zone: 7, psu: 0 },
  { value: "Palau", text: "Palau", eur: false, zone: 7, psu: 0 },
  { value: "Panama", text: "Panama", eur: false, zone: 7, psu: 0 },
  {
    value: "Papua New Guinea",
    text: "Papua New Guinea",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Paraguay", text: "Paraguay", eur: false, zone: 7, psu: 0 },
  { value: "Peru", text: "Peru", eur: false, zone: 7, psu: 0 },
  { value: "Philippines", text: "Philippines", eur: false, zone: 6, psu: 0 },
  { value: "Poland", text: "Poland", eur: true, zone: 2, psu: 0 },
  { value: "Portugal", text: "Portugal", eur: true, zone: 2, psu: 0 },
  { value: "Puerto Rico", text: "Puerto Rico", eur: false, zone: 7, psu: 0 },
  { value: "Qatar", text: "Qatar", eur: false, zone: 7, psu: 0 },
  { value: "Reunion", text: "Reunion", eur: true, zone: 6, psu: 0 },
  { value: "Romania", text: "Romania", eur: false, zone: 3, psu: 0 },
  {
    value: "Russian Federation",
    text: "Russian Federation",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Rwanda", text: "Rwanda", eur: false, zone: 7, psu: 0 },
  { value: "Saint Helena", text: "Saint Helena", eur: false, zone: 7, psu: 0 },
  {
    value: "Saint Kitts and Nevis",
    text: "Saint Kitts and Nevis",
    eur: false,
    zone: 7,
    psu: 0 },
  {
    value: "Saint Pierre and Miquelon",
    text: "Saint Pierre and Miquelon",
    eur: false,
    zone: 7,
    psu: 0 },
  {
    value: "Saint Vincent and the Grenadines",
    text: "Saint Vincent and the Grenadines",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Samoa", text: "Samoa", eur: false, zone: 7, psu: 0 },
  { value: "San Marino", text: "San Marino", eur: false, zone: 1, psu: 0 },
  {
    value: "Sao Tome and Principe",
    text: "Sao Tome and Principe",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Saudi Arabia", text: "Saudi Arabia", eur: false, zone: 7, psu: 0 },
  { value: "Senegal", text: "Senegal", eur: false, zone: 7, psu: 0 },
  {
    value: "Serbia and Montenegro",
    text: "Serbia and Montenegro",
    eur: false,
    zone: 4,
    psu: 0 },
  { value: "Seychelles", text: "Seychelles", eur: false, zone: 7, psu: 0 },
  { value: "Sierra Leone", text: "Sierra Leone", eur: false, zone: 7, psu: 0 },
  { value: "Singapore", text: "Singapore", eur: false, zone: 6, psu: 0 },
  { value: "Slovakia", text: "Slovakia", eur: true, zone: 3, psu: 0 },
  { value: "Slovenia", text: "Slovenia", eur: true, zone: 3, psu: 0 },
  {
    value: "Solomon Islands",
    text: "Solomon Islands",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Somalia", text: "Somalia", eur: false, zone: 7, psu: 0 },
  { value: "South Africa", text: "South Africa", eur: false, zone: 6, psu: 0 },
  { value: "Spain", text: "Spain", eur: true, zone: 1, psu: 0 },
  { value: "Sri Lanka", text: "Sri Lanka", eur: false, zone: 6, psu: 0 },
  { value: "Sudan", text: "Sudan", eur: false, zone: 7, psu: 0 },
  { value: "Suriname", text: "Suriname", eur: false, zone: 7, psu: 0 },
  { value: "Swaziland", text: "Swaziland", eur: false, zone: 7, psu: 0 },
  { value: "Sweden", text: "Sweden", eur: true, zone: 3, psu: 0 },
  { value: "Switzerland", text: "Switzerland", eur: false, zone: 4, psu: 0 },
  {
    value: "Syria",
    text: "Syria",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Taiwan", text: "Taiwan", eur: false, zone: 6, psu: 0 },
  { value: "Tajikistan", text: "Tajikistan", eur: false, zone: 7, psu: 0 },
  { value: "Tanzania", text: "Tanzania", eur: false, zone: 7, psu: 0 },
  { value: "Thailand", text: "Thailand", eur: false, zone: 6, psu: 0 },
  { value: "Timor-Leste", text: "Timor-Leste", eur: false, zone: 6, psu: 0 },
  { value: "Togo", text: "Togo", eur: false, zone: 7, psu: 0 },
  { value: "Tonga", text: "Tonga", eur: false, zone: 7, psu: 0 },
  {
    value: "Trinidad and Tobago",
    text: "Trinidad and Tobago",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Tunisia", text: "Tunisia", eur: false, zone: 6, psu: 0 },
  { value: "Turkey", text: "Turkey", eur: false, zone: 4, psu: 0 },
  { value: "Turkmenistan", text: "Turkmenistan", eur: false, zone: 7, psu: 0 },
  {
    value: "Turks and Caicos Islands",
    text: "Turks and Caicos Islands",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Tuvalu", text: "Tuvalu", eur: false, zone: 7, psu: 0 },
  { value: "Uganda", text: "Uganda", eur: false, zone: 7, psu: 0 },
  { value: "Ukraine", text: "Ukraine", eur: false, zone: 7, psu: 0 },
  {
    value: "United Arab Emirates",
    text: "United Arab Emirates",
    eur: false,
    zone: 6,
    psu: 0 },
  { value: "United Kingdom", text: "United Kingdom", eur: true, zone: 1, psu: 2 },
  { value: "United States", text: "United States", eur: false, zone: 5, psu: 1 },
  { value: "Uruguay", text: "Uruguay", eur: false, zone: 7, psu: 0 },
  { value: "Uzbekistan", text: "Uzbekistan", eur: false, zone: 7, psu: 0 },
  { value: "Vanuatu", text: "Vanuatu", eur: false, zone: 7, psu: 0 },
  { value: "Venezuela", text: "Venezuela", eur: false, zone: 7, psu: 0 },
  { value: "Viet Nam", text: "Viet Nam", eur: false, zone: 6, psu: 0 },
  {
    value: "Virgin Islands, British",
    text: "Virgin Islands, British",
    eur: false,
    zone: 7,
    psu: 0 },
  {
    value: "Virgin Islands, U.S.",
    text: "Virgin Islands, U.S.",
    eur: false,
    zone: 7,
    psu: 0 },
  {
    value: "Wallis and Futuna",
    text: "Wallis and Futuna",
    eur: false,
    zone: 7,
    psu: 0 },
  { value: "Yemen", text: "Yemen", eur: false, zone: 7, psu: 0 },
  { value: "Zambia", text: "Zambia", eur: false, zone: 7, psu: 0 },
  { value: "Zimbabwe", text: "Zimbabwe", eur: false, zone: 7, psu: 0 },
];
