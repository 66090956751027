import React from "react";
import TabMonoMorphCard from "./TabMonoMorphCard";
import "./TabMonoProduct.css";

const tabData = {
  tabs: [
    {
      name: "Mono",
      link: "#monohead",
    },
    {
      name: "Contextual Modulation",
      link: "#monocontextual",
    },
    {
      name: "Sequencer",
      link: "#monosequencer",
    },
    {
      name: "Randomization",
      link: "#monorandomize",
    },
    {
      name: "Sound Engine",
      link: "#monosoundengine",
    },
    {
      name: "Manual",
      link: "#",
    },
  ],
};
const price = "699";

class TabMonoProduct extends React.Component {
  render() {
    return (
      <>
        <TabMonoMorphCard
          fixed={this.props.fixed}
          currentSection={this.props.currentSection}
          price={price}
          tabData={tabData}
          product="mono"
          shopifyLink="Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzg1NDEyMTg4NTg1MQ=="
        />
      </>
    );
  }
}

export default TabMonoProduct;
